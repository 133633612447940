import { ServiceStatusInfo } from '@autonolas/frontend-library';
import { useHealthCheckup } from 'common-util/hooks/useHealthCheckup';
import PropTypes from 'prop-types';

const MINUTE = 60 * 1000;
const URLS = [
  'https://033b5159d2a067b3.agent.propel.autonolas.tech/healthcheck',
  'https://564d87c067c22ca8.agent.propel.autonolas.tech/healthcheck',
  'https://9d1917e5a1e0f049.agent.propel.autonolas.tech/healthcheck',
  'https://e8eb7e0ca2083d08.agent.propel.autonolas.tech/healthcheck',
];

const Footer = ({ setIsMinimized }) => {
  const [isHealthy] = useHealthCheckup(URLS, MINUTE);

  return (
    <ServiceStatusInfo
      appType="iekit"
      onMinimizeToggle={(e) => setIsMinimized(e)}
      isHealthy={isHealthy}
    />
  );
};

Footer.propTypes = {
  setIsMinimized: PropTypes.func.isRequired,
};

export default Footer;
